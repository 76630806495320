/* Base styles */
.cart-container {
  padding: 100px 20px 0 20px;
}

.cart-page {
  padding: 20px;
}

.cart-page h1 {
  margin-bottom: 20px;
}

.cart-list {
  list-style-type: none;
  padding: 0;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.cart-item-image {
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-img {
  max-width: 100%;
  max-height: 100%;
}

.cart-item-details {
  flex-grow: 1;
  padding: 0 20px;
}

.cart-item-title {
  font-size: 18px;
  margin: 0;
}

.cart-item-actions {
  display: flex;
  gap: 10px;
}

.cart-btn {
  background-color: #007bff;
  width: 140px;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.cart-btn:hover {
  background-color: #0056b3;
}

.cart-btn-buy {
  background-color: green;
  width: 140px;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.cart-btn-buy:hover {
  background-color: lightseagreen;
}


/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .cart-container {
    padding: 100px 15px 0 15px;
  }

  .cart-item-details {
    padding: 0 15px;
  }
  .cart-item-image {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 992px) {
  .cart-container {
    padding: 100px 10px 0 10px;
  }

  .cart-item-details {
    padding: 0 15px;
  }
  .cart-item-image {
    width: 100px;
    height: 100px;
  }

}

@media (max-width: 768px) {
  .cart-container {
    padding: 100px 5px 0 5px;
  }

  .cart-item {
    flex-direction: row;
    align-items: center;
  }

  .cart-item-details {
    padding: 10px 0;
  }

  .cart-item-actions {
    flex-direction: column;
    gap: 5px;
  }

  .cart-item-image {
    width: 100px;
    height: 100px;
  }

  .cart-item-title {
    font-size: 18px;
    padding: 0px 10px;
  }

  .cart-btn {
    width: 120px;
    padding: 10px 5px;
    font-size: 14px;

  }
  .cart-btn-buy{
    width: 120px;
    padding: 10px 5px;
    font-size: 14px;

  }
}

@media (max-width: 576px) {
  .cart-container {
    padding: 100px 5px 0 5px;
  }

  .cart-page h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .cart-item-image {
    width: 100px;
    height: 100px;
  }

  .cart-item-title {
    font-size: 16px;
    padding: 0px 10px;
  }

  .cart-item-image {
    width: 100px;
    height: 100px;
  }

  .cart-item-title {
    font-size: 16px;
    padding: 0px 10px;
  }

  .cart-btn {
    width: 100px;
    padding: 10px 5px;
    font-size: 12px;
  }

  .cart-btn-buy {
    width: 100px;
    padding: 10px 5px;
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .cart-container {
    padding: 100px 5px 0 5px;
  }

  .cart-page h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .cart-item-image {
    width: 100px;
    height: 100px;
  }

  .cart-item-image {
    width: 100px;
    height: 100px;
  }

  .cart-item-title {
    font-size: 12px;
    padding: 0px 10px;
  }

  .cart-btn {
    padding: 10px 5px;
    font-size: 10px;
  }

  .cart-btn-buy {
    padding: 10px 5px;
    font-size: 10px;
  }
}
