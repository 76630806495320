.dashboard-frame {
    width: 100%;
    height: 80px;
    font-size: 22px;
    border: none;
    position: fixed;
    z-index: 999;
}

.dashboard-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 0%, #001329 100%), #B2D5FF;
    padding: 10px 20px;
    position: relative;
    cursor: pointer;
}

.company-dashboard-logo {
    width: 60px;
    height: 60px;
}

.dashboard-title {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-size: 48px;
    line-height: 120%;
    display: inline-block;
    font-family: var(--font-times-new-roman);
    background: radial-gradient(50% 50% at 50% 50%, #d3d3d3, #858484 51.5%, #dcdcdc);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.dashboard-main {
    padding: 100px 20px 20px 20px;
}

.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.dashboard-card {
    border: 2px solid white;
    padding: 20px;
    background-color: #333;
    color: white;
    border-radius: 10px;
}

.dashboard-details {
    padding-bottom: 20px;
    text-align: center;
    font-family: "Times New Roman";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    background: radial-gradient(80% 80% at 50% 80%, #000, #858484 51.5%, #000);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


@media (max-width: 599px) {
    .dashboard-title {
        font-size: 36px;
    }

    .dashboard-details {
        font-size: 32px;
    }
}