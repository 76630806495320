.design-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.overlay-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 100px 20px 20px 20px;
}

.design-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;
  /* Ensure the zoomed image stays within the container */
}

.design-container img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  /* Smooth transition for zoom effect */
  cursor: zoom-in;
  /* Change cursor to indicate zoom action */
}

.design-container img.zoomed {
  transform: scale(2);
  /* Zoom in by scaling the image */
  cursor: zoom-out;
  /* Change cursor to indicate zoom out action */
}

.design-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 3px;
  background: linear-gradient(45deg, red, darkblue, blue, red);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1;
  margin: 0;
}

.overlay-design h1 {
  text-align: center;
  font-family: "Times New Roman";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  background: radial-gradient(80% 80% at 50% 80%, #000, #858484 51.5%, #000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Responsive styles (no changes needed, already handled) */

/* For screens larger than 1920px */
@media (min-width: 1920px) {
  .overlay-design {
    margin: 100px 20px 20px 20px;
  }
}

/* For screens between 1200px and 1920px */
@media (max-width: 1919px) and (min-width: 1200px) {
  .overlay-design {
    margin: 100px 20px 20px 20px;
  }

}

/* For screens between 768px and 1199px (tablets and small desktops) */
@media (max-width: 1199px) and (min-width: 768px) {
  .overlay-design {
    margin: 100px 20px 20px 20px;
  }

  .design-container {
    height: 60vh;
    /* Adjust height for smaller screens */
  }
}

/* For screens between 480px and 767px (phones in landscape) */
@media (max-width: 767px) and (min-width: 480px) {
  .overlay-design {
    margin: 100px 20px 20px 20px;
  }

  .design-container {
    height: 50vh;
    /* Adjust height for smaller screens */
  }
}

/* For screens smaller than 480px (phones in portrait) */
@media (max-width: 479px) {
  .overlay-design {
    margin: 100px 20px 20px 20px;
  }

  .design-container {
    height: 40vh;
    /* Adjust height for smaller screens */
  }
}

@media (max-width: 410px) {
  .overlay-design h1 {
    font-size: 36px;
  }
}