.detail-page {
  box-sizing: border-box;
}

.detail-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 100px 20px 20px 20px;
  overflow: hidden;
}

.main-column {
  flex-grow: 1;
  position: relative;
}

.art-item {
  position: sticky;
  top: 50px;
  padding: 20px 0;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  transition: top 0.5s ease;
}


.thumbnails {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.thumbnail {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.edit-flex-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.thumbnail:hover,
input[type="radio"]:checked+.thumbnail {
  border-color: #000;
}

.slider-container {
  width: 600px;
  height: 500px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 1 0 100%;
  box-sizing: border-box;
  height: 100%;
}

.slider-container img {
  width: 100%;
  height: 500px;
}

.slider-container video {
  width: 100%;
  height: 500px;
}

input[type="radio"] {
  display: none;
}

#slide1:checked~.slides {
  transform: translateX(0);
}

#slide2:checked~.slides {
  transform: translateX(-100%);
}

.slider-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  padding: 3px;
  background: linear-gradient(45deg, red, darkblue, blue, red);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1;
}

.art-scroll-handle {
  display: flex;
  gap: 20px;
}

.art-img-container {
  width: 100%;
  margin-top: 30px;
}

.art-details-text {
  position: sticky;
  top: 80px;
  padding: 20px 0;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  transition: top 0.5s ease;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  padding: 20px;
  column-gap: 20px;
}

.art-name {
  margin-top: 20px;
  font-family: "Times New Roman";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  background: radial-gradient(80% 80% at 50% 80%, #000, #858484 51.5%, #000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.art-description {
  font-size: large;
  color: #333;
}

.description-edit {
  width: 100%;
  height: 150px;
}

.buy-now-btn {
  width: 200px;
  height: 50px;
  padding: 10px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 20px;
}

.buy-now-btn:hover {
  background-color: lightseagreen;
}

.add-to-cart-btn {
  width: 200px;
  height: 100%;
  padding: 10px;
  background-color: #2666c5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 20px;
}

.delete-from-cart-btn {
  width: 200px;
  height: 100%;
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 20px;
}

.cancel-to-btn {
  width: 100px;
  margin-top: 20px;
  height: 100%;
  padding: 10px;
  background-color: #2666c5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.save-from-btn {
  width: 100px;
  margin-top: 20px;
  height: 100%;
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.add-to-cart-btn:hover {
  background-color: #0056b3;
}

.suggetion-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.suggetion-showcase {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.suggetion-card {
  width: 220px;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3), -1px -1px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
}

.suggetion-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.suggetion-image-wrapper {
  position: relative;
  width: 220px;
  height: 100%;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}

.suggetion-image {
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.suggetion-card:hover .suggetion-image {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.suggetion-card:hover .overlay {
  opacity: 1;
}

.suggetion-details-button {
  background-color: #ff4d4d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.suggetion-details-button:hover {
  background-color: #e60000;
}

.suggetion-title {
  color: #2D3648;
  font-family: "Times New Roman";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 15px;
}

.loader-load-shop {
  margin-bottom: 10px;
  animation: spin 2s linear infinite;
}

.loader-load-shop img {
  width: 100px;
  height: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.load-shop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.load-shop p {
  text-align: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(90deg, blue, lightblue, red);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  animation: gradientMove 2s linear infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.loader-load {
  margin-bottom: 5px;
  animation: spin 2s linear infinite;
}

.loader-load img {
  width: 50px;
  height: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.load {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.load p {
  text-align: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(90deg, blue, lightblue, red);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  animation: gradientMove 2s linear infinite;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

/* Responsive styles */
@media (max-width: 1920px) and (min-width: 1270px) {
  .detail-container {
    padding: 100px 30px 30px 30px;
  }

  .art-item {
    top: 60px;
    /* Adjust sticky position for medium-large screens */
    padding: 20px 10px;
    /* Adjust padding for medium-large screens */
    max-width: 100%;
    /* Ensure it fits within container */
  }
}

@media (max-width: 1270px) and (min-width: 1024px) {
  .detail-container {
    display: flex;
    flex-wrap: wrap;
    padding: 100px 20px 20px 20px;
  }

  .detail-container>* {
    flex: 1;
  }

  .load,
  .load-shop {
    flex: none;
    /* Prevent loader from growing and misaligning */
  }


  .main-column {
    order: 0;
  }

  .art-item {
    top: 50px;
    /* Adjust sticky position for smaller screens */
    padding: 15px 10px;
    /* Adjust padding for smaller screens */
    max-width: 100%;
    /* Ensure it fits within container */
  }

  .art-image-detail {
    width: 500px;
    height: 400px;
    margin-bottom: 15px;
  }

  .art-details-text {
    position: relative;
    padding: 20px;
    top: 0;
  }
}

@media (max-width: 1024px) and (min-width: 900px) {
  .detail-container {
    flex-direction: column;
    align-items: center;
    padding: 100px 20px 20px 20px;
  }

  .main-column,
  .side-column {
    width: 100%;
    order: unset;
  }

  .art-item {
    top: 20px;
    /* Adjust sticky position for even smaller screens */
    padding: 10px 10px;
    max-width: 100%;
    /* Ensure it fits within container */
  }

  .art-details-text {
    position: relative;
    top: 0;
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0px;
    padding: 0px 20px;
  }

}

@media (max-width: 899px) and (min-width: 742px) {
  .detail-container {
    flex-direction: column;
    align-items: center;
    padding: 100px 10px 10px 10px;
  }

  .main-column,
  .side-column {
    width: 100%;
    order: unset;
  }

  .art-item {
    top: 20px;
    /* Adjust sticky position for even smaller screens */
    padding: 10px 10px;
    max-width: 100%;
    /* Ensure it fits within container */
  }

  .art-details-text {
    position: relative;
    top: 0;
    margin-top: 0px;
    padding: 0px 20px;
  }

  .art-image-detail {
    width: 100%;
    height: auto;
  }

  .art-name {
    margin-top: 30px;
  }

}

@media (max-width: 742px) and (min-width: 643px) {
  .detail-container {
    flex-direction: column;
    align-items: center;
    padding: 60px 10px 20px 10px;
  }

  .main-column,
  .side-column {
    width: 100%;
    order: unset;
  }

  .art-item {
    top: 20px;
    padding: 10px 10px;
    max-width: 100%;
  }

  .art-details-text {
    position: relative;
    top: 0;
    margin-top: 0px;
    padding: 0px 20px;
  }

  .art-image-detail {
    width: 100%;
    height: auto;
  }

  .art-scroll-handle {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .thumbnails {
    display: flex;
    flex-direction: row;
  }

  .art-name {
    margin-top: 30px;
  }
}

@media (max-width: 643px) and (min-width: 480px) {
  .detail-container {
    flex-direction: column;
    align-items: center;
    padding: 60px 10px 20px 10px;
  }

  .main-column,
  .side-column {
    width: 100%;
    order: unset;
  }

  .art-item {
    top: 0;
    padding: 15px 5px;
  }

  .art-image-detail {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .art-details-text {
    position: relative;
    top: 0;
    margin-top: 0px;
    padding: 10px;
  }

  .art-scroll-handle {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .thumbnails {
    display: flex;
    flex-direction: row;
  }

  .slider-container {
    width: 100%;
    height: 500px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }

  .art-name {
    margin-top: 20px;
  }

}

@media (max-width: 480px) and (min-width: 418px) {
  .detail-container {
    flex-direction: column;
    align-items: center;
    padding: 60px 10px 20px 10px;
  }

  .main-column,
  .side-column {
    width: 100%;
    order: unset;
  }

  .art-item {
    top: 0;
    padding: 15px 5px;
  }

  .art-image-detail {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .art-details-text {
    position: relative;
    top: 0;
    margin-top: 0px;
    padding: 10px;
  }

  .art-scroll-handle {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .thumbnails {
    display: flex;
    flex-direction: row;
  }

  .slider-container {
    width: 100%;
    height: 500px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }

  .slider-container {
    height: 450px;
  }

  .slider-container img {
    height: 450px;
  }
  
  .slider-container video {
    height: 450px;
  }
}

@media (max-width: 418px) and (min-width: 349px) {
  .detail-container {
    flex-direction: column;
    align-items: center;
    padding: 60px 10px 20px 10px;
  }

  .main-column,
  .side-column {
    width: 100%;
    order: unset;
  }

  .art-item {
    top: 0;
    padding: 15px 5px;
  }

  .art-image-detail {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .art-details-text {
    position: relative;
    top: 0;
    margin-top: 0px;
    padding: 10px;
  }

  .art-scroll-handle {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .thumbnails {
    display: flex;
    flex-direction: row;
  }

  .slider-container {
    width: 100%;
    height: 380px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }

  .slider-container img {
    height: 380px;
  }
  
  .slider-container video {
    height: 380px;
  }

}
