.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  overflow: hidden; /* Added */
}

.contact-container h1 {
    text-align: center;
    font-family: "Times New Roman";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    background: radial-gradient(80% 80% at 50% 80%, #000, #858484 51.5%, #000);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    overflow: hidden; /* Added */
}

.form-wrapper {
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 70%;
  gap: 20px;
  overflow: hidden; /* Added */
}

.contact-image {
  background: url('../Assets/collage.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  width: 100%;
  overflow: hidden; /* Added */
}

.contact-logo {
  width: 100%;
  height: 100%;
  overflow: hidden; /* Added */
}

.image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Added */
}

.contact-form {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Added */
}

.form-group-contact {
  margin-bottom: 15px;
  overflow: hidden; /* Added */
}

.name-fields {
  display: flex;
  gap: 10px;
  overflow: hidden; /* Added */
}

.contact_label {
  font-weight: bold;
  overflow: hidden; /* Added */
}

.name-fields input[type="text"],
.form-group-contact input[type="email"],
.form-group-contact input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  overflow: hidden; /* Added */
}

.form-group-contact textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  height: 100px;
  overflow: hidden; /* Added */
}

.submit-btn {
  padding: 10px 20px;
  border: none;
  background-color: #2666c5;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden; /* Added */
}

.submit-btn:hover {
  background-color: #0056b3;
  overflow: hidden; /* Added */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden; /* Added */
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden; /* Added */
}

.popup-close-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden; /* Added */
}

/* Media Queries */
@media (min-width: 1920px) {
  .form-wrapper {
    width: 60%;
    overflow: hidden; /* Added */
  }

  .image-placeholder {
    height: 400px;
    overflow: hidden; /* Added */
  }
}

@media (max-width: 1200px) {
  .form-wrapper {
    width: 70%;
    overflow: hidden; /* Added */
  }
}

@media (max-width: 992px) {
  .form-wrapper {
    flex-direction: column;
    width: 80%;
    overflow: hidden; /* Added */
  }

  .contact-form,
  .contact-image {
    width: 100%;
    overflow: hidden; /* Added */
  }

  .contact-form {
    margin-top: 20px;
    overflow: hidden; /* Added */
  }

  .image-placeholder {
    height: 350px;
    overflow: hidden; /* Added */
  }
}

@media (max-width: 768px) {
  .contact-container {
    padding-top: 100px;
    padding-bottom: 20px;
    overflow: hidden; /* Added */
  }

  .form-wrapper {
    padding: 15px;
    overflow: hidden; /* Added */
  }

  .image-placeholder {
    height: 300px;
    overflow: hidden; /* Added */
  }

  .submit-btn {
    padding: 8px 16px;
    overflow: hidden; /* Added */
  }
}

@media (max-width: 576px) {
  .form-wrapper {
    padding: 10px;
    overflow: hidden; /* Added */
  }

  .image-placeholder {
    height: 250px;
    overflow: hidden; /* Added */
  }

  .contact-container h1 {
    margin-bottom: 10px;
    overflow: hidden; /* Added */
  }

  .contact-form {
    margin-top: 10px;
    overflow: hidden; /* Added */
  }

  .submit-btn {
    padding: 6px 12px;
    overflow: hidden; /* Added */
  }

  .form-group-contact input[type="text"],
  .form-group-contact input[type="email"],
  .form-group-contact input[type="tel"],
  .form-group-contact textarea {
    padding: 8px;
    overflow: hidden; /* Added */
  }
}

@media (max-width: 350px) {
  .contact-container {
    padding-top: 100px;
    padding-bottom: 20px;
    overflow: hidden; /* Added */
  }

  .contact-container h1 {
    margin-bottom: 5px;
    overflow: hidden; /* Added */
  }

  .contact-form {
    margin-top: 5px;
    overflow: hidden; /* Added */
  }

  .image-placeholder {
    height: 150px;
    overflow: hidden; /* Added */
  }

  .submit-btn {
    padding: 5px 10px;
    overflow: hidden; /* Added */
  }

  .form-group-contact input[type="text"],
  .form-group-contact input[type="email"],
  .form-group-contact input[type="tel"],
  .form-group-contact textarea {
    padding: 6px;
    overflow: hidden; /* Added */
  }
}
