.frame {
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 1000;
    background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 0%, #001329 100%), #B2D5FF;
}

.nav-parent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0px 40px;
}


.nav-space {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.nav-child {
    display: inline-flex;
    align-items: center;
    gap: 40px;
}

.nav-link {
    position: relative;
    display: inline-block;
    color: #FFF;
    font-family: "Times New Roman";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
}

.nav-link i {
    padding-right: 10px;
}

.nav-link::after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    bottom: 0px;
    width: 0;
    height: 2px;
    margin-top: 15px;
    background-color: #FFF;
    transition: width 0.3s ease-in-out;
}

.nav-link:hover::after {
    width: 105%;
}

.nav-link.active::after {
    width: 105%;
    transition: none;
}



.nav-link-drop {
    position: relative;
    display: inline-block;
    color: #FFF;
    font-family: "Times New Roman";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
    list-style: none;
}

.nav-link-drop:hover{
    border-radius: 0px 0px 5px 5px;
    background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 10%, #0c0c0c 100%), #B2D5FF;
}


.company-logo {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.company-name {
    color: #FFF;
    font-family: "Times New Roman";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.User-link {
    display: inline-flex;
    align-items: center;
    gap: 20px;
}

.User-link i {
    padding-right: 10px;
}


.user-nav-link {
    position: relative;
    display: inline-block;
    color: black;
    font-family: "Times New Roman";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    border: 2px #7DA8DC solid;
    border-radius: 12px;
    background: #7DA8DC;
    padding: 8px 16px;
    align-items: center;
}

.user-nav-link:hover {
    color: #FFF;
    background: none;
}

.user-new-nav-link {
    position: relative;
    display: inline-block;
    color: #FFF;
    font-family: "Times New Roman";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    border: 2px #7DA8DC solid;
    border-radius: 12px;
    padding: 8px 16px;
    align-items: center;
}

.user-new-nav-link:hover {
    color: black;
    background: #7DA8DC ;
}

.nav-toggle-button {
    display: none;
    font-size: 22px;
    background-color: transparent;
    width: 40px;
    color: white;
    border: none;
    cursor: pointer;
}

.nav-dropdown {
    display: none;
    flex-direction: column;
    background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 0%, #001329 100%), #B2D5FF;
    position: absolute;
    top: 100%;
    width: calc(100% - 80px);
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.nav-dropdown.show {
    display: flex;
}

.drop-nav-link{
    position: relative;
    display: inline-block;
    color: #FFF;
    font-family: "Times New Roman";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
    border-bottom: #FFF 1px solid;
    list-style: none;
}

.drop-nav-link i {
    padding-right: 10px;
}

.drop-nav-link:hover{
    background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 10%, #0c0c0c 100%), #B2D5FF;
}

.drop-nav-link.active{
    background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 10%, #0c0c0c 100%), #B2D5FF;
}
.nav-dropdown .drop-nav-link:last-child {
    border-bottom: none;
}

.drop-nav-nav-link{
    position: relative;
    display: inline-block;
    color: #FFF;
    font-family: "Times New Roman";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
    border-bottom: #FFF 1px solid;
    list-style: none;
}

.drop-nav-nav-link i {
    padding-right: 10px;
}

.drop-nav-nav-link:hover{
    background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 10%, #0c0c0c 100%), #B2D5FF;
}

.drop-nav-name{
    position: relative;
    display: inline-block;
    color: #FFF;
    font-family: "Times New Roman";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
}
.drop-nav-name::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px 5px 0px 0px;
    padding: 3px;
    background: linear-gradient(45deg, red, lightblue, lightblue, red);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
    z-index: 1;
    margin: 0;
  }
.user-dropdown-menu {
    display: none;
    flex-direction: column;
    background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 0%, #001329 100%), #B2D5FF;
    position: absolute;
    top: 80px;
    right: 0;
    width: 200px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-dropdown-menu.show {
    display: flex;
}

.user-dropdown{
    display: flex;
    flex-direction: column;
}

.userlogo{
    position: relative;
    display: inline-block;
    color: #FFF;
    font-family: "Times New Roman";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
}

.userlogo::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px 5px 0px 0px;
    padding: 3px;
    background: linear-gradient(45deg, red, lightblue, lightblue, red);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: destination-out;
    mask-composite: exclude;
    pointer-events: none;
    z-index: 1;
    margin: 0;
  }

  .user-icon {
    position: relative;
    width: 40px;
    height: 40px;
    background: #5ba5ff; 
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
}


@media screen and (max-width: 1180px) {
    .nav-child {
        display: none;
    }

    .get-in {
        display: none;
    }

    .nav-toggle-button {
        display: block;
    }

}

@media screen and (max-width: 480px) {
    .nav-parent {
        padding: 0px 20px;
    }

    .nav-dropdown {
        width: calc(100% - 40px);
    }
}