.footer {
    width: 100%;
    background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 0%, #001329 100%), #B2D5FF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.footer-background {
    position: relative;
    width: 100%;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;
    z-index: 1;
}

.footer-background-image {
    width: 300px;
    height: 300px;
    opacity: 0.4;
    z-index: 1;
}

.footer-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #FFF;
    z-index: 2;
    text-align: center;
    gap: 40px;
    padding: 0px 40px;
}

.footer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
}

.footer-section-contact {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.footer-section-contact h2 {
    width: 353px;
    position: relative;
    font-size: 48px;
    line-height: 120%;
    display: inline-block;
    font-family: var(--font-times-new-roman);
    background: radial-gradient(50% 50% at 50% 50%, #d3d3d3, #858484 51.5%, #dcdcdc);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.footer-section-text {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    gap: 12px;
}

.footer-section-text p {
    text-align: left;
}

.footer-section-text span {
    display: block;
}

.align-icon-text {
    display: flex;
    align-items: center;
    gap: 12px;
}

.align-icon-text p {
    margin: 0;
}


.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 40px 0px 32ch;
}

.footer-bottom p {
    flex: 1;
    text-align: center;
    margin: 0;
    font-size: 20px;
}

.footer-section-social {
    display: flex;
    justify-content: flex-end;
    padding-top: 5px;
}

.social-icons {
    display: flex;
    gap: 16px;
}

.footer-logo {
    height: 40px;
    width: 40px;
}


@media (max-width: 1039px) {

    .footer-content {
        gap: 20px;
    }

    .footer-background {
        height: 500px;
    }

    .footer-section-text p {
        font-size: 18px;
    }

    .footer-bottom {
        flex-direction: column;
        padding: 20px;
        gap: 20px;
    }

    .footer-bottom p {
        order: 1;
    }

    .footer-section-social {
        justify-content: center;
    }
}


@media (max-width: 400px) {

    .footer-section-contact h2 {
        font-size: 46px;
    }

    
    .footer-section-text p {
        font-size: 16px;
    }

    .footer-bottom p {
        font-size: 16px;
    }

    .footer-logo {
        height: 36px;
        width: 36px;        
    }
    .footer-content{
        gap: 40px;
    }
  }