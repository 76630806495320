body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.new-product {
    text-align: center;
    padding: 0 20px 20px 20px;
    transition: 0.3s; /* Added */
}

.new-product h1 {
    margin-bottom: 10px;
    transition: 0.3s; /* Added */
}

.new-product h4 {
    margin-bottom: 20px;
    transition: 0.3s; /* Added */
}

.gallery-container {
    padding: 100px 80px 40px 80px;
    transition: 0.3s; /* Added */
}

.art-grid {
    display: grid;
    grid-template-rows: auto 1fr;
    justify-content: center;
    transition: 0.3s; /* Added */
}

.art-showcase {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: dense; /* Fill in gaps */
    gap: 40px;
    margin-top: 20px;
    width: 100%;
    transition: 0.3s; /* Added */
}

.art-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3), -1px -1px 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s, 0.3s; /* Added */
    width: 300px;
    height: 320px;
}

.art-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.art-image-wrapper {
    position: relative;
    width: 300px;
    height: 100%;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    transition: 0.3s; /* Added */
}

.art-image {
    width: 100%;
    height: 100%;
    transition: transform 0.3s, 0.3s; /* Added */
}

.art-card:hover .art-image {
    transform: scale(1.05);
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s, 0.3s; /* Added */
}

.art-card:hover .overlay {
    opacity: 1;
}

.view-details-button {
    background-color: #ff4d4d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s, 0.3s; /* Added */
}

.view-details-button:hover {
    background-color: #e60000;
}

.art-title {
    color: #2D3648;
    font-family: "Times New Roman";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    margin: 15px;
    transition: 0.3s; /* Added */
}

.art-add {
    background-color: #ff4d4d;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s, 0.3s; /* Added */
}

.art-add:hover {
    background-color: #e60000;
}

.loader-load-shop-shop {
    margin-bottom: 10px;
    animation: spin 2s linear infinite;
}

.loader-load-shop-shop img {
    width: 100px;
    height: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.load-shop-shop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
}

.load-shop-shop p {
    text-align: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: transparent;
    background: linear-gradient(90deg, blue, lightblue, red);
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 200% 100%;
    animation: gradientMove 2s linear infinite;
    transition: 0.3s; /* Added */
}

@keyframes gradientMove {
    0% {
        background-position: 0% 0%;
    }

    50% {
        background-position: 100% 0%;
    }

    100% {
        background-position: 0% 0%;
    }
}

/* Media Queries for responsiveness */

@media (max-width: 1920px) and (min-width: 1375px) {
    .art-showcase {
        grid-template-columns: repeat(4, 1fr);
    }

}

@media (max-width: 1375px) and (min-width: 1324px) {
    .art-showcase {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 18px;
    }

}

/* Slightly smaller screens */
@media (max-width: 1324px) and (min-width: 1278px) {
    .art-showcase {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 10px;
    }
}

@media (max-width: 1278px) and (min-width: 1159px) {
    .art-showcase {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 100px;
    }
}

@media (max-width: 1159px) and (min-width: 1065px) {
    .art-showcase {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
    }
}

@media (max-width: 1065px) and (min-width: 992px) {
    .art-showcase {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
    }
}

/* Medium screens */
@media (max-width: 995px) and (min-width: 968px) {
    .art-showcase {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 10px;
    }
}

/* Smaller screens */
@media (max-width: 968px) and (min-width: 765px) {
    .art-showcase {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 100px;
    }
}

/* Extra small screens */
@media (max-width: 765px) and (min-width: 706px) {
    .art-showcase {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 50px;
    }
}

@media (max-width: 706px) and (min-width: 679px) {
    .art-showcase {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
    }
}

@media (max-width: 679px) and (min-width: 660px) {
    .art-showcase {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
    }
}

@media (max-width: 660px) and (min-width: 480px) {
    .art-showcase {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 10px;
    }
}

@media (max-width: 480px) and (min-width: 340px) {
    .art-showcase {
        grid-template-columns: repeat(1, 1fr);
    }
}
