/* Global Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Times New Roman';
}

body {
  line-height: 1.6;
}

.home-page-pad {
  width: 100%;
  padding: 0px 80px;
}

/* Custom Carousel Section */
.custom-carousel-section {
  padding-top: 80px;
}

/* Item Display */
.item-display {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-showcase {
  display: flex;
  width: 100%;
  margin: 40px 0px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.item-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3), -1px -1px 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 300px;
  height: 320px;
}

.item-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.item-image-wrapper {
  position: relative;
  width: 300px;
  height: 100%;
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}

.item-image {
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.item-card:hover .item-image {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.item-card:hover .overlay {
  opacity: 1;
}

.view-details-button {
  background-color: #ff4d4d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-details-button:hover {
  background-color: #e60000;
}

.item-title {
  color: #2D3648;
  font-family: "Times New Roman";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  margin: 15px;
  
}

/* Slogan Section */

.center-slogan {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slogan {
  width: clamp(150px, 50vw, 400px);
  text-align: center;
  background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 0%, #001329 100%), #B2D5FF;
  padding: 20px;
  color: white;
  position: relative;
  display: inline-block;
  clip-path: polygon(15% 0%, 100% 0, 85% 100%, 0% 100%);
  border-radius: 0;
}

.slogan-cust {
  width: clamp(250px, 70vw, 900px);
  text-align: center;
  background: radial-gradient(51.73% 67.14% at 50% 54.6%, #00428F 0%, #001329 100%), #B2D5FF;
  padding: 20px;
  color: white;
  position: relative;
  display: inline-block;
  clip-path: polygon(10% 0%, 100% 0, 90% 100%, 0% 100%);
}


.slogan-company {
  line-height: 120%;
  background: radial-gradient(50% 50% at 50% 50%, #d3d3d3, #858484 51.5%, #dcdcdc);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 42px;
  padding: 0px 20px;
}


.slogan_client {
  text-align: center;
  font-family: "Times New Roman";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  background: radial-gradient(50% 50% at 50% 50%, #000, #858484 51.5%, #000);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Custom Showcase */
.custom-showcase {
  display: flex;
  flex-direction: row;
  gap: 60px;
  width: 100%;
  padding: 40px 0px;
  justify-content: center;
  align-items: center;
}

.info-container {
  width: 100%;
  aspect-ratio: 1;
  max-width: 494px;
  position: relative;
  display: inline-block;
  border: 6px solid #FFF;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3), -1px -1px 10px rgba(0, 0, 0, 0.3);
}


.info-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

/* Overlay effect on hover */
.info-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

/* Show the overlay when hovering over the image */
.info-container:hover::after {
  opacity: 1;
}

/* Initially hide the description */
.desc-info {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-family: var(--font-times-new-roman);
  color: #fff;
  text-align: center;
  transition: opacity 0.3s ease;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 2;
}

.info-button {
  width: 50px;
  height: 50px;
  opacity: 0;
  font-size: 24px;
  color: #FFF;
  position: absolute;
  cursor: pointer;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  padding: 10px;
  border: none;
  box-sizing: border-box;
  background-color: #e60000;
  border-radius: 50%;
  z-index: 2;
  /* Ensure the text is above the overlay */
}

/* Show the description when hovering over the container */
.info-container:hover .desc-info {
  opacity: 1;
  /* Show description on hover */
}

.info-container:hover .info-button {
  opacity: 1;
  /* Show button on hover */
}




/* Client Section */
.client-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 40px 0px;
}

.client-item {
  width: 100%;
  /* Full width */
  aspect-ratio: 1;
  /* Maintain square aspect ratio */
  max-width: 335px;
  /* Set a maximum width */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: bold;
}

.client-item:hover {
  cursor: pointer;
  transform: translateY(-5px);
}

.client-img {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2), -1px -1px 10px rgba(0, 0, 0, 0.2);
}

.client-img:hover {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1), -1px -1px 10px rgba(0, 0, 0, 0.1);
}


/* Slider Section */
.headline-container p {
  font-size: 42px;
  position: absolute;
  bottom: 120px;
  left: 50px;
  color: rgb(255, 255, 255);
}

.headline-container h3 {
  position: absolute;
  bottom: 90px;
  left: 60px;
  cursor: pointer;
  color: rgb(255, 255, 255);
}

.loader-load {
  margin-bottom: 5px;
  animation: spin 2s linear infinite;
}

.loader-load img {
  width: 50px;
  height: auto;
}


.services-section {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  width: 100%;
  padding: 40px 0px;
}

.services-technology {
  overflow: hidden;
  width: 50%;
  display: inline-flex;
  padding: 16px 38px 40px 38px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 49px 421px 119px 0px rgba(115, 115, 115, 0.00) inset, 31px 269px 108px 0px rgba(115, 115, 115, 0.01) inset, 18px 152px 92px 0px rgba(115, 115, 115, 0.05) inset, 8px 67px 68px 0px rgba(115, 115, 115, 0.09) inset, 2px 17px 37px 0px rgba(115, 115, 115, 0.10) inset;
}

.services-objective {
  overflow: hidden;
  width: 50%;
  display: inline-flex;
  padding: 16px 10px 40px 10px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 49px 421px 119px 0px rgba(115, 115, 115, 0.00) inset, 31px 269px 108px 0px rgba(115, 115, 115, 0.01) inset, 18px 152px 92px 0px rgba(115, 115, 115, 0.05) inset, 8px 67px 68px 0px rgba(115, 115, 115, 0.09) inset, 2px 17px 37px 0px rgba(115, 115, 115, 0.10) inset;
}

.services-objective b,
.services-technology b {
  position: relative;
  font-size: 32px;
  line-height: 120%;
  display: inline-block;
  font-family: var(--font-times-new-roman);
  color: #000;
  text-align: center;
}

.image-objective {
  width: 70%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 200px;
}

.image-objective img {
  width: 100%;
  height: 200px;
}

.technology-description,
.objective-description {
  position: relative;
  font-size: 18px;
  line-height: 140%;
  font-family: var(--font-georgia);
  color: #2d3648;
  text-align: center;
  display: inline-block;
}

.image-wrapper img {
  width: 100%;
  border-radius: 10px;
}

.quote,
.technology-quote {
  position: relative;
  font-size: 22px;
  line-height: 120%;
  font-family: var(--font-times-new-roman);
  color: #004089;
  text-align: center;
  display: inline-block;
}

.display-attributes {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 40px 0px;
}

.attribute-image {
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  height: auto;
  flex-shrink: 0;
  position: relative;
  border-radius: 8px;
  object-fit: cover;
}

.attribute-info,
.attribute-info-reverse {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: left;
}

.attribute-info h2,
.attribute-info-reverse h2 {
  color: #000;
  font-family: "Times New Roman";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.attribute-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  font-family: "Times New Roman";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  gap: 16px;
  padding: 0px 10px;
}

.attribute-list-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  font-family: "Times New Roman";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  gap: 16px;
  padding: 0px 10px;
}

.attribute-list li {
  text-align: left;
  align-items: flex-start;
}

.attribute-list-list li {
  text-align: left;
  align-items: flex-start;
}


.load {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
}

.load p {
  text-align: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(90deg, blue, lightblue, red);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  animation: gradientMove 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradientMove {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 100% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

@media (max-width: 1240px) {
  .home-page-pad {
    padding: 0px 80px;
  }

  .client-header {
    gap: 40px;
  }
}


@media (max-width: 1069px) {
  .client-header {
    flex-wrap: wrap;
    gap: 20px;
  }
}

@media (max-width: 1087px) {
  .display-attributes {
    flex-direction: column;
  }

  .attribute-info-reverse {
    order: 1;
  }

  .attribute-info,
  .attribute-info-reverse {
    text-align: center;
  }

}

@media (max-width: 989px) {

  /* Adjust flexbox layout */
  .custom-showcase {
    flex-direction: column;
  }

  .services-section {
    flex-direction: column;
    align-items: center;
  }

  .services-technology,
  .services-objective {
    width: 80%;
  }
}


@media (max-width: 768px) {

  .home-page-pad {
    padding: 0px 40px;
  }

  .services-technology,
  .services-objective {
    width: 80%;
  }

  .services-section {
    gap: 20px;
  }
}

@media (max-width: 756px) {
  .slogan {
    padding: 20px;
    width: 90%;
    display: block;
  }

  .slogan-cust {
    padding: 20px;
    width: 100%;
    display: block;
  }
}

@media (max-width: 576px) {

  .home-page-pad {
    padding: 0px 20px;
  }

  .services-technology,
  .services-objective {
    width: 100%;
  }

}

@media (max-width: 510px) {

  .slogan-company {
    font-size: 32px;
  }

}

@media (max-width: 480px) {

  .slogan-company {
    font-size: 28px;
  }

  .attribute-list {
    padding-left: 50px;
  }
  .attribute-list-list {
    padding-left: 65px;
  }
  .image-objective {
    width: 90%;
  }
}
@media (max-width: 434px) {
  .services-objective b,
.services-technology b {
  font-size: 26px;
}

.quote,
.technology-quote {
  font-size: 20px;
}

.objective-description,
.technology-description {
  font-size: 18px;
}

}

@media (max-width: 386px) {
  .slogan {
    padding: 20px;
    width: 100%;
    display: block;
  }

  .slogan-cust {
    padding: 20px;
    width: 100%;
    display: block;
  }

  .slogan-company {
    font-size: 24px;
  }

  .slogan_client {
    font-size: 40px;
  }

  .attribute-info h2,
  .attribute-info-reverse h2 {
    font-size: 26px;
  }

  .attribute-list {
    font-size: 18px;
  }

  .attribute-list-list {
    font-size: 18px;
  }

  .desc-info {
    top: 45%;
    font-size: 20px;
  }

  .image-objective {
    width: 100%;
  }
  .services-objective b,
  .services-technology b {
    font-size: 22px;
  }
  
  .quote,
  .technology-quote {
    font-size: 16px;
  }
  
  .objective-description,
  .technology-description {
    font-size: 14px;
  }

  .services-technology,
  .services-objective {
    width: 100%;
    gap: 20px;
  }
}