/* Base styles */
.forget-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;

}

.fr-container {
    display: flex;
    margin: 20px;
    flex-direction: column;
    width: 60vh;
    height: auto;
    background-color: white;
    border-radius: 10px;
    box-shadow:
        0 15px 20px rgba(0, 0, 0, 0.25),
        0 6px 8px rgba(0, 0, 0, 0.15),
        0 2px 4px rgba(0, 0, 0, 0.1);
}

.otp-p {
    margin-top: 10px;
    font-size: large;
}

.Otp-box {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    gap: 20px;
}

.Otp-input {
    width: 60px;
    height: 60px;

}

.Otp-input input {
    width: 100%;
    height: 100%;
    font-size: xx-large;
    text-align: center;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 10px rgba(34, 27, 88, 0.6);
}

.verify_button {
    margin-top: 40px;
    background-color: #2666c5;
    padding: 15px;
    font-size: medium;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px rgba(8, 7, 16, 0.6);
    color: white;
}

.verify_button:hover {
    background-color: #8ecae6;
}

.middel {
    padding: 30px;
    text-align: center;
}

.middel h2 {
    text-align: center;
}

.rec-btn {
    width: 40%;
    padding: 10px;
    background-color: #2666c5;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
}

.rec-btn:hover {
    background-color: #0056b3;
}

.forget-company-logo {
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
}

.forget-company-logo-two {
    width: 200px;
    height: 200px;
    justify-content: center;
    align-items: center;
}

.recover-form-group,
.forget-form-group {
    margin-bottom: 15px;
}

.recover-form-group label,
.forget-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
}

.recover-form-group input,
.forget-form-group input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

.rec-home-link {
    position: relative;
    top: 10px;
    left: 20px;
    color: #2666c5;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.password-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.password-wrapper input {
    flex: 1;
    padding-right: 40px;
    /* Adjust padding to make room for the icon */
}

.password-toggle {
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.password-toggle svg {
    font-size: 1rem;
    /* Adjust icon size */
}


/* For screens larger than 1200px (optional breakpoint for very large screens) */
@media (min-width: 1200px) {
    .fr-container {
        max-width: 500px;
    }

    .otp-input {
        width: 80px;
        height: 80px;
    }

    .verify_button {
        font-size: large;
    }


}

/* For screens between 768px and 1200px */
@media (max-width: 1200px) and (min-width: 768px) {
    .fr-container {
        max-width: 450px;
    }

    .otp-input {
        width: 70px;
        height: 70px;
    }

    .verify_button {
        font-size: medium;
    }


}

/* For screens between 600px and 768px */
@media (max-width: 768px) and (min-width: 600px) {
    .fr-container {
        max-width: 400px;
    }

    .otp-input {
        width: 60px;
        height: 60px;
    }

    .verify_button {
        font-size: small;
    }

}

/* For screens between 349px and 600px */
@media (max-width: 600px) and (min-width: 480px) {
    .fr-container {
        max-width: 90%;
        margin: 10px;
    }

    .otp-input {
        width: 50px;
        height: 50px;
    }

    .verify_button {
        font-size: small;
        padding: 12px;
    }

}

/* For screens smaller than 349px */
@media (max-width: 480px) and (min-width: 349px) {
    .forget-container {
        max-height: 85vh;
        /* Restrict height to ensure content is scrollable */
        overflow-y: hidden;
    }

    .fr-container {
        max-width: 85%;
        margin: 5px;
    }

    .otp-input {
        width: 40px;
        height: 40px;
    }

    .verify_button {
        font-size: small;
        padding: 10px;
    }

}