/* Base styles */
.add-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;

}

.add-form {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.add-form h1 {
    line-height: 120%;
    background: radial-gradient(50% 50% at 50% 50%, #d3d3d3, #858484 51.5%, #dcdcdc);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 36px;
    padding: 20px 20px;
    text-align: center;
}

.add-form h3 {
    line-height: 120%;
    font-size: 24px;
    padding: 5px 0px;
    text-align: center;
}

.upload-title {
    width: 100%;
    height: 40px;
    font-size: large;
    border: 1px solid gray;
    border-radius: 5px;
    padding-left: 10px;
}

.upload-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-file {
    font-size: medium;
}

.new-btn {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.description {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    padding: 10px;
    resize: vertical;
    font-size: large;
}

.upnew-btn {
    width: 100px;
    padding: 10px;
    background-color: #2666c5;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top: 0;
    transition: 0.3s;
}

.upnew-btn:hover {
    background-color: #0056b3;
}