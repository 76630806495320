/* slider.css */
.container__slider {
  position: relative;
  width: 100%;
  height: 85vh;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: black;
}

.container__slider>button {
  color: whitesmoke;
  background-color: black;
  opacity: 0.05;
  font-size: 32px;
}

.container__slider>button:hover {
  opacity: 0.5;
}


.slider__btn-next {
  position: absolute;
  right: 0px;
  width: 50px;
  height: 100%;
  cursor: pointer;
}

.slider__btn-prev {
  position: absolute;
  left: 0px;
  width: 50px;
  height: 100%;
  cursor: pointer;
}

.slider__item {
  box-sizing: border-box;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  transform: translateX(0);
  transition: transform 1s ease;
}

.slider__item-active-1 {
  transform: translateX(0);
}

.slider__item-active-2 {
  transform: translateX(-100%);
}

.slider__item-active-3 {
  transform: translateX(-200%);
}

.slider__item-active-4 {
  transform: translateX(-300%);
}

.slider__item-active-5,
.slider__item-active-6,
.slider__item-active-7 {
  transform: translateX(-300%);
}

.slider__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 536px) {
  .container__slider {

    height: 65vh;
  }

}
